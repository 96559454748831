import Vue from 'vue';
import Router from 'vue-router';
import brandRoutes from './modules/brandRoutes';
import baseStylesRoutes from './modules/baseStylesRoutes';
import webRoutes from './modules/webRoutes';
import centralRoutes from './modules/centralRoutes';
import assetRoutes from './modules/assetRoutes';
import marketingRoutes from './modules/marketingRoutes';

Vue.use(Router);

// TODO nest routes based on section
const router = new Router({
  mode: 'history',
  routes: [
    ...brandRoutes,
    ...baseStylesRoutes,
    ...webRoutes,
    ...centralRoutes,
    ...assetRoutes,
    ...marketingRoutes,
    {
      path: '/:catchAll(.*)',
      name: 'NotFound',
      component: () => {
        return import('../views/NotFound.vue');
      }
    }
  ]
});

export default router;
