import store from '@/store/store';

const baseStylesRoutes = store.state.searchStore.search
  .filter((route) => {
    return route.section === 'basestyles';
  })
  .map((route) => {
    return {
      path: route.path,
      name: route.name,
      component: route.component
    };
  });

export default baseStylesRoutes;