<template>
  <div id="hamburger-container">
      <slideout-panel></slideout-panel>
    <img src="~@/assets/img/chargehub_icon.svg" class="ch-logo" alt="">
    <a href="#" class="hamburger-btn" @click="showPanel">
      <i class="fas fa-bars"></i>
    </a>
  </div>
</template>

<script>
import SlideOutSideBar from '@/components/layout/SlideOutSideBar.vue';

export default {
  name: 'HamburgerButton',

  data() {
    return {
      panel: null
    };
  },

  // eslint-disable-next-line vue/no-unused-components
  components: { SlideOutSideBar },

  methods: {
    showPanel() {
      this.panel = this.$showPanel({
        component: SlideOutSideBar,
        width: '400px',
        openOn: 'left',
        cssClass: 'slide-out-panel'
      });
    }
  }
};
</script>

<style lang="scss">
#hamburger-container {
  position: fixed;
  width: 60px;
  height: 100%;
  left: 0;
  top: 0;
  background-color: $chGreen;
  z-index: 999;
}

#hamburger-container .ch-logo {
  color: #FFF;
  width: 30px;
  height: auto;
  margin: 20px auto;
  display: block;
}

#hamburger-container .hamburger-btn {
  color: #FFF;
  width: 24px;
  font-size: 24px;
  height: auto;
  margin: 30px auto;
  display: block;

  &:hover {
    text-decoration: none;
  }
}

.slideout-panel .slideout-wrapper .slideout {
  background-color: $chGreen !important;
}

</style>