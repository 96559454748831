<template>
<div>
  <hr>
  <ul class="main-list">
    <li>
      &copy; {{ new Date().getFullYear() }} Mogile Technologies
    </li>
    <li class="links left-link">
      <a target="_blank" href="https://chargehub.com/en/privacy.html">Privacy</a>
    </li>
    <li class="links">
      <a target="_blank" href="https://chargehub.com/en/terms.html">Terms of Use</a>
    </li>
  </ul>
</div>
</template>

<script>
export default {
  name: 'Footer'
};
</script>

<style lang="scss" scoped>
  .main-list {
    padding: 0;
    list-style: none;
    display: flex;

    .left-link {
      margin-left: auto;
    }

    .links {
      padding-left: 40px;
    }

    a {
      color: black;
    }

    a:hover {
      color: #474747;
    }
  }
</style>
