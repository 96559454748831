<template>
  <div id="app">
    <SideBar/>
    <div id="main-container" class="container">
      <div id="main-content">
        <h1 class="big-header">{{ title }}</h1>
        <div id="content">
          <router-view/>
        </div>
        <Footer/>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/layout/SideBar.vue';
import Footer from '@/components/layout/Footer.vue';

export default {
  name: 'App',

  components: {
    SideBar,
    Footer
  },

  data: () => {
    return {
      page: null,
      title: null,
      breadcrumbs: null
    };
  },

  watch: {
    $route() {
      const pageName = this.$store.getters.getSearchName(window.location.pathname)[0];
      const splitName = pageName.split('/');

      if (splitName.length < 3) {
        splitName.unshift('General ');
      }

      this.title = splitName[splitName.length - 1];
      splitName.splice(splitName.length - 1, 1, '');
      this.breadcrumbs = splitName.join('/ ');

      const pageTitle = `ChargeHub Design System - ${this.title}`;
      document.title = pageTitle;
    }
  }
};
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

#main-container {
  flex: auto;
  flex-shrink: 1;
  flex-grow: 1;
  z-index: 1;
}

#main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 960px;
  margin: 0 auto;
}

#content {
  flex: 1;
  padding: 0 48px;
}

code {
  background-color: #e4e4e4;
  padding: 10px;
  text-align: left;
  display: block;
}

@media only screen and (max-width: 1096px) {
  #main-container {
    flex-basis: 600px;
  }
}

</style>
