<template>
<form @submit.prevent>
  <div class="searchbar form-row">
    <div class="col-auto">
      <div class="input-group">
          <input
            id="searchbar-input"
            class="form-control"
            type="text"
            autocomplete="off"
            @keyup="getSearchResults()"
            @blur="toggleIsActive(false)"
            @focus="toggleIsActive(true)"
            v-model="searchInput">
          <SearchBarResults
            :searchResults="searchResults"
            :searchInput="searchInput"
            :isActive="isActive"/>
      </div>
    </div>
  </div>
</form>

</template>

<script>
const SearchBarResults = () => {
  return import('./SearchBarResults.vue');
};

export default {
  name: 'SearchBar',

  components: {
    SearchBarResults
  },

  data() {
    return {
      searchInput: null,
      searchResults: [],
      isActive: false
    };
  },

  methods: {
    getSearchResults() {
      if (this.searchInput.trim() === 0) {
        this.searchResults = [];
      } else {
        this.searchResults = this.$store.getters.filterSearch(this.searchInput);
      }
    },

    toggleIsActive(isActive) {
      setTimeout(() => {
        this.isActive = isActive;
      }, 80);
    }
  }

};
</script>

<style lang="scss">
  #searchbar-input {
    position: relative;
    border-radius: 25px;
    border: thin #D2D2D2 solid;
    background: url('../../assets/img/search-solid.png') no-repeat scroll 7px 7px;
    background-color: #FFF;
    background-position: 10px center;
    padding-left:30px;
    width: 240px;
  }

</style>
