import Vue from 'vue';
import VueSlideoutPanel from 'vue2-slideout-panel';
import VTooltip from 'v-tooltip';
import VueScrollTo from 'vue-scrollto';
import App from './App.vue';
import router from './router/routes';
import store from './store/store';

Vue.config.productionTip = false;

Vue.use(VueSlideoutPanel);
Vue.use(VueScrollTo);
Vue.use(VTooltip);

new Vue({
  router,
  store,
  render: (h) => {
    return h(App);
  }
}).$mount('#app');
