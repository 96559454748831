/**
 * @author Greg Fitzpatrick
 *
 * @description the searchStore vuex module is used both
 * both for the searchbar results dropdown & the routes
 * modules. The fields are used as follows:
 *
 *  searchName  -> name displayed in searchbar results
 *  section     -> section used for filtering in route modules
 *  name        ->  name used in router object
 *  path        -> path to component used in router-view (path to actual page)
 *  tags        -> tags used for search filtering
 *  component   -> component used for router-view (actual page)
 */

const searchStore = {
  state: () => {
    return {
      search: [
      // BRAND
        {
          searchName: 'Brand / Welcome',
          section: 'brand',
          path: '/',
          tags: 'welcome',
          name: 'Welcome',
          component: () => {
            return import('@/views/WelcomePage.vue');
          }
        },
        {
          searchName: 'Brand / Guidelines',
          section: 'brand',
          path: '/guidelines',
          tags: 'guidelines',
          name: 'Guidelines',
          component: () => {
            return import('@/views/Guidelines.vue');
          }
        },
        // BASE STYLES
        {
          searchName: 'Base Styles / Colours',
          section: 'basestyles',
          path: '/colours',
          tags: 'colours',
          name: 'Colours',
          component: () => {
            return import('@/views/basestyles/Colours.vue');
          }
        },
        {
          searchName: 'Base Styles / Typography',
          section: 'basestyles',
          path: '/typography',
          tags: 'typography',
          name: 'Typography',
          component: () => {
            return import('@/views/basestyles/Typography.vue');
          }
        },
        {
          searchName: 'Base Styles / Visual Elements',
          section: 'basestyles',
          path: '/visual-elements',
          tags: 'visual elements',
          name: 'Visual Elements',
          component: () => {
            return import('@/views/basestyles/VisualElements.vue');
          }
        },
        {
          searchName: 'Base Styles / Icons',
          section: 'basestyles',
          path: '/icons',
          tags: 'icons',
          name: 'Icons',
          component: () => {
            return import('@/views/basestyles/Icons.vue');
          }
        },
        {
          searchName: 'Base Styles / Grid System',
          section: 'basestyles',
          path: '/grid-system',
          tags: 'grid-system',
          name: 'Grid System',
          component: () => {
            return import('@/views/basestyles/GridSystem.vue');
          }
        },
        // WEB
        {
          searchName: 'Web / Components / Buttons',
          section: 'web',
          name: 'Buttons',
          path: '/web/buttons',
          tags: 'buttons',
          subHeaders: [
            { header: 'Radio Buttons', scrollTo: '#radio-buttons' },
            { header: 'CheckBox Buttons', scrollTo: '#checkbox-buttons' }
          ],
          component: () => {
            return import('@/views/web/components/Buttons.vue');
          }
        },
        {
          searchName: 'Web / Components / Announcements',
          section: 'web',
          name: 'Announcements',
          path: '/web/announcements',
          tags: 'announcements',
          subHeaders: [
            { header: 'Alert', scrollTo: '#alertAnnouncement' },
            { header: 'General', scrollTo: '#generalAnnouncement' },
            { header: 'Port Added', scrollTo: '#portAddedAnnouncement' },
            { header: 'Port Removed', scrollTo: '#portRemovedAnnouncement' }
          ],
          component: () => {
            return import('@/views/web/components/Announcements.vue');
          }
        },
        {
          searchName: 'Web / Components / Inputs',
          section: 'web',
          name: 'Inputs',
          path: '/web/inputs',
          tags: 'inputs',
          subHeaders: [
            { header: 'Simple Input', scrollTo: '#simpleInput' },
            { header: 'Standard Input', scrollTo: '#standardInput' },
            { header: 'Dropdown', scrollTo: '#dropdown' },
            { header: 'Text Area', scrollTo: '#textArea' },
            { header: 'Toggle Input', scrollTo: '#toggleInput' }
          ],
          component: () => {
            return import('@/views/web/components/Inputs.vue');
          }
        },
        {
          searchName: 'Web / Components / Slider',
          section: 'web',
          name: 'Slider',
          path: '/web/slider',
          tags: 'slider',
          component: () => {
            return import('@/views/web/components/Slider.vue');
          }
        },
        {
          searchName: 'Web / Components / Animations',
          section: 'web',
          name: 'Animations',
          path: '/web/animations',
          tags: 'animation animations',
          component: () => {
            return import('@/views/web/components/Animations.vue');
          }
        },
        {
          searchName: 'Web / Components / Cards',
          section: 'web',
          name: 'Cards',
          path: '/web/cards',
          tags: 'cards card',
          component: () => {
            return import('@/views/web/components/Cards.vue');
          }
        },
        // CENTRAL
        {
          searchName: 'Central / Components / Buttons',
          section: 'central',
          name: 'Buttons',
          path: '/central/buttons',
          tags: 'buttons',
          component: () => {
            return import('@/views/central/components/Buttons.vue');
          }
        },
        // MARKETING
        {
          searchName: 'Marketing / Resources / Social Media Copy',
          section: 'marketing',
          name: 'Social Media Copy',
          path: '/marketing/social-media-copy',
          tags: 'social media copy',
          component: () => {
            return import('@/views/marketing/resources/SocialMediaCopy.vue');
          }
        },
        // ASSETS
        {
          searchName: 'Assets / Images',
          section: 'assets',
          name: 'Images',
          path: '/assets/images',
          tags: 'images',
          component: () => {
            return import('@/views/assets/Images.vue');
          }
        },
        {
          searchName: 'Assets / Open Source Libraries',
          section: 'assets',
          name: 'Open Source Libraries',
          path: '/assets/open-source-libraries',
          tags: 'open sources libraries',
          component: () => {
            return import('@/views/assets/Open-Source-Libraries.vue');
          }
        }
        // {
        //   searchName: 'Assets / CDN',
        //   section: 'assets',
        //   name: 'CDN',
        //   path: '/assets/cdn',
        //   tags: 'cdn content delivery network',
        //   component: () => {
        //     return import('@/views/assets/CDN.vue');
        //   }
        // }
      ]
    };
  },
  getters: {
    filterSearch: ({ search }) => {
      return (filter) => {
        const results = [];
        if (filter.length === 0) {
          return results;
        }
        search.sort((a, b) => {
          const nameA = a.section.toUpperCase(); // ignore upper and lowercase
          const nameB = b.section.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        search.forEach((result) => {
          if (result.tags.toLowerCase().includes(filter.toLowerCase())
            || result.searchName.toLowerCase().includes(filter.toLowerCase())) {
            results.push({ text: result.searchName, link: result.path });
          }
          if (result.subHeaders) {
            result.subHeaders.forEach((header) => {
              if (header.header.toLowerCase().includes(filter.toLowerCase())) {
                results.push({
                  text: header.header,
                  link: result.path,
                  scrollTo: header.scrollTo,
                  breadCrumb: result.searchName
                });
              }
            });
          }
        });
        return results;
      };
    },

    buildRoutesForSidebar: ({ search }) => {
      return (section) => {
        search.sort((a, b) => {
          const nameA = a.name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        // Place welcome at the top of the sidebar
        const index = search.findIndex((x) => {
          return x.name === 'Welcome';
        });
        const tmp = search[index];
        search.splice(index);
        search.unshift(tmp);

        return search
          .filter((route) => {
            return route.section === section;
          })
          .map((route) => {
            return {
              name: route.name,
              path: route.path,
              subHeaders: route.subHeaders,
              section: route.section
            };
          });
      };
    },

    getSearchName: ({ search }) => {
      return (filePath) => {
        search.sort((a, b) => {
          const nameA = a.section.toUpperCase(); // ignore upper and lowercase
          const nameB = b.section.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        return search
          .filter((route) => {
            return route.path === filePath;
          })
          .map((route) => {
            return route.searchName;
          });
      };
    }
  }
};

module.exports = searchStore;
