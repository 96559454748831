<template>
  <li class="nav-item">
    <div class="main-header">
      <router-link :to="link" v-scroll-to="'#app'">
        {{ name }}
      </router-link>
      <div @click="dropdownToggle" class="dropdownArrow-container">
        <i
          class="fa fw icon icon-chevron-down"
          v-if="subHeaders"
        ></i>
      </div>
    </div>
    <ul class="inner-list" v-if="showSubheaders">
      <li
        v-for="header in subHeaders"
        :key="header.header"
        class="inner-list-item"
        v-scroll-to="header.scrollTo"
      >
        <router-link :to="{ path: link, hash: header.scrollTo }">
          {{ header.header }}
        </router-link>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'SideBarItem',
  data() {
    return {
      showSubheaders: false
    };
  },
  props: {
    name: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    subHeaders: {
      type: Array
    }
  },
  methods: {
    dropdownToggle(event) {
      this.showSubheaders = !this.showSubheaders;
      const arrow = event.target.getElementsByClassName('icon-chevron-down')[0];
      arrow.classList.toggle('turnArrow');
    }
  }
};
</script>

<style lang="scss">
li.nav-item {
  list-style-type: none;
  padding: 0 0 0 4px;
  margin: 8px 0 8px 18px;
  font-family: "Roboto";
  font-size: 14px;
  display: block;
}

.main-header {
  display: flex;
  align-items: center;
}

.main-header div i{
  transition: 0.25s ease-out;
  width: fit-content;
  pointer-events: none;
}

.dropdownArrow-container {
  margin-left: auto;
  cursor: pointer;
  width: 20px;
  text-align: right;
  margin-right: 8%;
}

.inner-list {
  margin: 0 !important;
  padding: 0;
}

.inner-list-item {
  list-style-type: none;
  padding: 0 0 0 4px;
  margin: 8px 0 8px 18px;
  font-family: "Roboto";
  font-size: 14px;
  display: block;
}
</style>
