const sectionStore = {
  state: () => {
    return {
      selectedSection: 0
    };
  },
  getters: {
    getSelectedSection: ({ selectedSection }) => {
      return selectedSection;
    },
    getSelectedSectionString: ({ selectedSection }) => {
      switch (selectedSection) {
        case 0:
          return 'web';
        case 1:
          return 'mobile';
        case 2:
          return 'central';
        case 3:
          return 'marketing';
        default:
          return 'default case in $store.getters.getSelectedSectionString';
      }
    }
  },
  mutations: {
    changeSelectedSection(state, payload) {
      state.selectedSection = payload;
    }
  }
};

module.exports = sectionStore;