<template>
<div class="slideout-menu-container">
  <img src="@/assets/img/chargehub_log_white.png" class="slideout-logo" alt="">
    <ul>
      <li :class="currentSelection === 0 ? 'selected' : ''">
        <a
          @click="selectSection(0)"
          href="#">
            <i class="fas fa-desktop section-icon"></i>
            Web
          </a>
        </li>
      <!-- <li :class="currentSelection === 2 ? 'selected' : ''">
        <a
          @click="selectSection(2)"
          href="#">
            <i class="far fa-mobile section-icon"></i>
            Mobile
        </a>
      </li> -->
      <!-- <li :class="currentSelection === 1 ? 'selected' : ''">
        <a
          @click="selectSection(1)"
          href="#">
            <i class="fas fa-chart-line section-icon"></i>
            Central
        </a>
      </li> -->
      <!-- <li :class="currentSelection === 3 ? 'selected' : ''">
        <a
          @click="selectSection(3)"
          href="#">
            <i class="fa fw icon icon-lightbulb section-icon"></i>
            Marketing
        </a>
      </li> -->
    </ul>
</div>
</template>

<script>
export default {
  name: 'SlideOutSideBar',

  data() {
    return {
      currentSelection: null
    };
  },

  created() {
    this.currentSelection = this.$store.getters.getSelectedSection;
  },

  methods: {
    selectSection(selection) {
      this.$store.commit('changeSelectedSection', selection);
      // close panel
      this.$emit('closePanel', {});
    }
  }
};
</script>

<style lang="scss">
  .slide-out-panel {
    background-color: $chGreen;
  }

  .slideout-menu-container {
    width: 250px;
    margin: 25px;

    ul {
      margin: 0;
      padding: 0;
    }

    a {
      text-decoration: none;
      color: #FFF;
    }

    li {
      list-style-type: none;
      color: #FFF;
      font-family: 'brandon_grotesqueregular';
      font-size: 24px;
      margin: 10px 0;
    }
  }

  .selected {
    border-bottom: solid thin #FFF;
  }

  .slideout-logo {
    width: 250px;
    height: auto;
  }

  .section-icon {
    display: inline-block;
    width: 50px;
    text-align: center;
  }
</style>
