import Vue from 'vue';
import Vuex from 'vuex';
import searchStore from './modules/search';
import sectionStore from './modules/sections';

Vue.use(Vuex);

/**
 * selectedSection refers to the hamburger slide out menu options
 *  0 -> web  1 -> mobile   2 -> central 3 -> marketing
 */

export default new Vuex.Store({
  modules: {
    searchStore,
    sectionStore
  }
});