<template>
<div id="sidebar-container">
    <HamburgerMenu/>
      <div id="sidebar-content">
        <SearchBar/>
          <div id="sidebar-list">
            <ul>
              <li class="nav-header d-flex align-items-center mt-0">
                <img class="nav-ch-logo mr-2" src="../../assets/img/chargehub_icon_grey.svg" alt="">
                Brand
              </li>
              <SideBarItem
                :key="i"
                v-for="(route, i) in brandLinks"
                :name="route.name"
                :link="route.path" />
            </ul>

            <ul>
              <li class="nav-header d-flex align-items-center">
                <i class="far fa-palette mr-2"></i>
                Base Styles
              </li>
              <SideBarItem
                :key="i"
                v-for="(route, i) in baseStylesLinks"
                :name="route.name"
                :link="route.path" />
            </ul>

            <ul v-if="selectedSection != 3">
              <li class="nav-header d-flex align-items-center">
                <i class="fas fa-code mr-2"></i>
                Components
              </li>
              <template v-if="selectedSection === 0">
                <SideBarItem
                    :key="i"
                    v-for="(route, i) in webLinks"
                    :name="route.name"
                    :link="route.path"
                    :subHeaders="route.subHeaders" />
              </template>
              <template v-if="selectedSection === 1">
                <SideBarItem
                    :key="i"
                    v-for="(route, i) in centralLinks"
                    :name="route.name"
                    :link="route.path" />
              </template>
              <!-- <template v-if="selectedSection === 2">
                <SideBarItem
                    :key="i"
                    v-for="(route, i) in mobileLinks"
                    :name="route.name"
                    :link="route.path" />
              </template> -->
            </ul>

            <ul v-if="selectedSection === 3">
              <li class="nav-header d-flex align-items-center">
                <i class="fa fw icon icon-folder mr-2"></i>
                Resources
              </li>
              <SideBarItem
                    :key="i"
                    v-for="(route, i) in marketingLinks"
                    :name="route.name"
                    :link="route.path" />
            </ul>

            <ul>
              <li class="nav-header d-flex align-items-center">
                <i class="far fa-copy mr-2"></i>
                Assets
              </li>
              <SideBarItem
                :key="i"
                v-for="(route, i) in assetLinks"
                :name="route.name"
                :link="route.path" />
            </ul>
          </div>
      </div>
</div>
</template>

<script>
import SearchBar from '@/components/searchbar/SearchBar.vue';
import HamburgerMenu from '@/components/HamburgerMenu.vue';
import SideBarItem from './SideBarItem.vue';

export default {
  name: 'Sidebar',

  components: {
    SearchBar,
    HamburgerMenu,
    SideBarItem
  },

  data() {
    return {
      brandLinks: null,
      baseStylesLinks: null,
      webLinks: null,
      mobileLinks: null,
      centralLinks: null,
      assetLinks: null
    };
  },

  mounted() {
    this.brandLinks = this.$store.getters.buildRoutesForSidebar('brand');
    this.baseStylesLinks = this.$store.getters.buildRoutesForSidebar('basestyles');
    this.webLinks = this.$store.getters.buildRoutesForSidebar('web');
    // this.mobileLinks = this.$store.getters.buildRoutesForSidebar('mobile');
    this.centralLinks = this.$store.getters.buildRoutesForSidebar('central');
    this.marketingLinks = this.$store.getters.buildRoutesForSidebar('marketing');
    this.assetLinks = this.$store.getters.buildRoutesForSidebar('assets');
  },

  computed: {
    selectedSection() {
      return this.$store.getters.getSelectedSection;
    }
  }
};
</script>

<style lang="scss">
#sidebar-container {
  background-color: #EFEFEF;
  flex-basis: 340px;
  flex-grow: 0;
  flex-shrink: 0;
}

#sidebar-list{
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 80px);
  width: 260px;
  margin-top: 20px;
}

#sidebar-content {
  position: fixed;
  top: 20px;
  left: 80px;
  height: auto;
  width: 260px;

  .nav-ch-logo {
    width: auto;
    height: 12px;
  }

  ul {
    margin: 0 0 0 0;
    padding: 0;

    li.nav-header {
    text-transform:none;
    color: #474747;
    font-weight: 800;
    margin-top: 24px;

      i {
        font-size: 12px;
        display: inline-block;
      }
    }

    li.nav-item a {
      text-decoration: none;
      color: #272727;
      width: 100%;
    }

    li.nav-item a:not(.router-link-exact-active) {
      padding: 4px;

      &:hover {
        width: 100%;
        padding: 4px;
        border-radius: 5px;
        text-decoration: underline;
        color: #000;
      }
    }
  }
}

.router-link-exact-active {
  background-color: #e0e0e0;
  border-radius: 5px;
  padding: 4px;
  font-weight: 700;
  padding-left: 8px;
  transition: ease all .3s;
}
</style>
